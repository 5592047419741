body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --primary:#1f3e72;
  --secondary:rgba(255,255,255,0.78);
  --black:#131110;
  --blue-gradient:linear-gradient(97.05deg,#4066ff 3.76%, #2949c6 100%);
  --orange-gradient:linear-gradient(270deg,#ffb978 0%, #ff922d 100%);
  --blue:#4066ff;
  --lightBlue:#eeeeff;
  --shadow:0px 23px 21px -8px rgba(136,160,255,0.25);
}
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins",sans-serif;
  scroll-behavior: smooth;
}
a{
  color: inherit;
  text-decoration: none;
}
.paddings{
  padding: 1.5rem;
}
.innerWidth{
  width: 100%;
}
.flexCenter{
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexEnd{
  display: flex;
justify-content: flex-end;
align-items: center;

}
.flexColStart{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
}
.flexColCenter{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColEnd{
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}
.primaryText{
  color: 1f3e72;
  font-weight: bold;
  font-style: 2rem;
}
.secondaryText{
  color: rgb(181, 176, 176);
  font-size: 0.9rem;
}
.orangeText{
  color: orange;
  font-style: 1.5rem;
  font-weight: 600;
}

.button{
  font-weight: 500;
  padding: 0.6rem 1.4em;
  color: white;
  background: var(--blue-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in-out;
}
.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}


